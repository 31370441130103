











































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import { Endpoint } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import Fields from '@/components/form/Fields.vue'
import { EndpointFragment, EndpointUpdateFragment } from './fragments'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields
  },
  apollo: {
    savedEndpoint: {
      query: gql`
        query getEndpoint($endpointId: ID) {
          savedEndpoint: endpoint(endpointId: $endpointId) {
            ...Endpoint
            collection {
              _id
              environmentId
              fields {
                value: name
                label
              }
            }
          }
        }
        ${EndpointFragment}
      `,
      variables() {
        return {
          endpointId: this.componentId
        }
      }
    }
  }
})
export default class EndpointEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: String, default: 'primary' }) accentColor!: string

  savedEndpoint: Endpoint | null = null
  endpoint: Partial<Endpoint> = {}

  saving = false
  valid = false

  @Watch('savedEndpoint')
  update(newData: Endpoint) {
    this.$set(this, 'endpoint', _cloneDeep(this.savedEndpoint))
  }

  @Watch('endpoint.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  endpointTypes = [
    { value: 'list', label: 'Lista', color: 'pink', icon: 'border_all' },
    { value: 'view', label: 'Ver uno', icon: 'visibility' },
    { value: 'form', label: 'Formulario', color: 'purple', icon: 'keyboard' },
    { value: 'indicator', label: 'Indicador', color: 'cyan', icon: 'subtitles' }
  ]

  async save() {
    if (!this.endpoint || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($endpointId: ID, $endpoint: UpdateEndpointInput) {
            updateEndpoint(endpointId: $endpointId, endpoint: $endpoint) {
              ...Endpoint
              collection {
                _id
                environmentId
                fields {
                  value: name
                  label
                }
              }
            }
          }
          ${EndpointFragment}
        `,
        // Parameters
        variables: {
          endpointId: this.endpoint._id,
          endpoint: cleanData(this.endpoint, EndpointUpdateFragment)
        }
      })

      this.savedEndpoint = result.data.updateEndpoint
      this.$emit('save', result.data.updateEndpoint)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async addToken() {
    if (!this.endpoint || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($endpointId: ID) {
            addTokenToEndpoint(endpointId: $endpointId) {
              ...Endpoint
              collection {
                _id
                environmentId
                fields {
                  value: name
                  label
                }
              }
            }
          }
          ${EndpointFragment}
        `,
        // Parameters
        variables: {
          endpointId: this.endpoint._id
        }
      })

      this.savedEndpoint = result.data.addTokenToEndpoint
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async removeToken(token: string) {
    if (!this.endpoint || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($endpointId: ID, $token: String) {
            deleteEndpointToken(endpointId: $endpointId, token: $token) {
              ...Endpoint
              collection {
                _id
                environmentId
                fields {
                  value: name
                  label
                }
              }
            }
          }
          ${EndpointFragment}
        `,
        // Parameters
        variables: {
          endpointId: this.endpoint._id,
          token
        }
      })

      this.savedEndpoint = result.data.deleteEndpointToken
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        '¿Seguro que quieres eliminar por completo este endpoint del ambiente?'
      ))
    )
      return
    if (!this.endpoint || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($endpointId: ID) {
            removeEndpoint(endpointId: $endpointId)
          }
        `,
        // Parameters
        variables: {
          endpointId: this.endpoint._id
        }
      })
      this.$emit('delete', result.data.removeEndpoint)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  get dirty() {
    return !_isEqual(this.endpoint, this.savedEndpoint)
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
